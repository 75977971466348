import React from 'react';
import CategoriesMobile from '../CategoriesMobile/CategoriesMobile';

const MobileCategories: React.FC = () => {
  return (
    <div
      // sx={{ zIndex: 16 }}
      // PaperProps={{
      //   sx: {
      //     height: 'calc(100vh - 65px)',
      //     padding: '12px',
      //     paddingBottom: '56px',
      //   },
      // }}
      // anchor="bottom"
      // open={true}
      // elevation={10}
      // hideBackdrop={true}
      style={{ padding: 12 }}
    >
      <CategoriesMobile />
    </div>
  );
};

export default MobileCategories;
