import React from 'react';

import Head from 'next/head';
import MobileCategories from '../../components/MobileNav/MobileCategories';
import SiteLayout from '../../Layout/SiteLayout';
import { getUserInfoSSR } from '../../utils/cf';

const Categories: React.FC = () => {
  return (
    <>
      <Head>
        <title>Categories - Driffle</title>
      </Head>
      <SiteLayout>
        <MobileCategories />
      </SiteLayout>
    </>
  );
};

export default Categories;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
