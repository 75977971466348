import React from 'react';

import { exportPlatformIcon } from '../Icons/PlatformIcons';

import dynamic from 'next/dynamic';
import { useLocale } from '../../contexts/LocalizationContext';
import { genresNav, giftCardsNav, platforms } from '../Categories/Categories';
import {
  CategoriesContainer,
  Column,
  GenresContainer,
  GenresList,
  GiftCardsContainer,
  GiftCardsList,
  ItemContainer,
  ItemTitle,
  PlatformIcon,
  PlatformsContainer,
  PlatformsList,
  Title,
} from './CategoriesMobileStyles';
const Link = dynamic(() => import('next/link'), {
  ssr: false,
});
const CategoriesMobile: React.FC = () => {
  const { messages } = useLocale();
  const { platforms_msg, gift_cards_msg, genres_msg } = messages || {};

  return (
    <CategoriesContainer>
      <Column>
        <PlatformsContainer>
          <Title>{platforms_msg}</Title>
          <PlatformsList>
            {platforms.map((cur, i) => (
              <Link
                key={i}
                href={{
                  pathname: `${cur.url}`,
                  query: cur.query,
                }}
                passHref
                prefetch={false}
              >
                <a>
                  <ItemContainer>
                    <ItemTitle>{cur.name}</ItemTitle>
                    <PlatformIcon>
                      {exportPlatformIcon(
                        cur.platform,
                        '24px',
                        '24px',
                        '0 0 0 0'
                      )}
                    </PlatformIcon>
                  </ItemContainer>
                </a>
              </Link>
            ))}
          </PlatformsList>
        </PlatformsContainer>
        <GiftCardsContainer>
          <Title>{gift_cards_msg}</Title>
          <GiftCardsList>
            {giftCardsNav.map((cur, i) => (
              <Link
                key={i}
                href={{
                  pathname: `${cur.url}`,
                  query: cur.query,
                }}
                passHref
                prefetch={false}
              >
                <a>
                  <ItemContainer>
                    <ItemTitle>{cur.name}</ItemTitle>
                  </ItemContainer>
                </a>
              </Link>
            ))}
          </GiftCardsList>
        </GiftCardsContainer>
      </Column>
      <Column>
        <GenresContainer>
          <Title>{genres_msg}</Title>
          <GenresList>
            {genresNav.map((cur, i) => (
              <Link
                key={i}
                href={{
                  pathname: `${cur.url}`,
                  query: cur.query,
                }}
                passHref
                prefetch={false}
              >
                <a>
                  <ItemContainer>
                    <ItemTitle>{cur.name}</ItemTitle>
                  </ItemContainer>
                </a>
              </Link>
            ))}
          </GenresList>
        </GenresContainer>
      </Column>
    </CategoriesContainer>
  );
};

export default CategoriesMobile;
