import styled from 'styled-components';

import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: ${COLORS.white};
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
  border: none;
  position: absolute;
  width: 832px;
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  z-index: 10;
  transform: translateX(-30%);
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
  & > div:last-of-type {
    border-left: 1px solid ${COLORS.gray2};
  }
  @media (max-width: ${WIDTH.laptopMax}) {
    transform: translateX(-50%);
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const Column = styled.div`
  padding: 0 32px;
`;

export const GenresContainer = styled.div``;
export const PlatformsContainer = styled.div``;
export const GiftCardsContainer = styled.div``;

export const Title = styled.h3`
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  font-family: Onest-Bold;
  color: ${COLORS.black};
  margin-bottom: 24px;
`;

export const ItemContainer = styled.div`
  border-radius: 8px;
  background: ${COLORS.greyLight2};
  padding: 16px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${COLORS.grey};
  }
`;

export const ItemTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-family: Onest-Bold;
  color: ${COLORS.black};
  text-transform: capitalize;
`;

export const GenresList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;

export const PlatformsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-bottom: 48px;
`;
export const GiftCardsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const PlatformIcon = styled.div`
  margin-top: 8px;
`;
