import styled from 'styled-components';

export const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 4px;
  padding-bottom: 16px;
`;

export const Column = styled.div`
  padding: 0;
`;

export const GenresContainer = styled.div``;

export const PlatformsContainer = styled.div``;

export const GiftCardsContainer = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 24px;
`;

export const ItemContainer = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s4};
  padding: 16px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.palette.background.bg1};
  }
`;

export const ItemTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  text-transform: capitalize;
`;

export const GenresList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;

export const PlatformsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-bottom: 32px;
`;

export const GiftCardsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const PlatformIcon = styled.div`
  margin-top: 8px;
`;
