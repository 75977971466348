import React from 'react';

import dynamic from 'next/dynamic';
import { NavbarPopoverListInterface } from '../../Interface/NavbarInterface';
import { useLocale } from '../../contexts/LocalizationContext';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import {
  CategoriesContainer,
  Column,
  GenresContainer,
  GenresList,
  GiftCardsContainer,
  GiftCardsList,
  ItemContainer,
  ItemTitle,
  PlatformIcon,
  PlatformsContainer,
  PlatformsList,
  Title,
} from './CategoriesStyles';
const Link = dynamic(() => import('next/link'), {
  ssr: false,
});
interface PlatformItemInteface extends NavbarPopoverListInterface {
  platform: string;
}

export const platforms: Array<PlatformItemInteface> = [
  {
    name: 'steam',
    platform: 'Steam',
    url: '/store/platform/steam',
    query: {},
  },
  {
    name: 'xbox',
    platform: 'Xbox Live',
    url: '/store/platform/xbox-live',
    query: {},
  },
  {
    name: 'rockstar games',
    platform: 'Rockstar Games',
    url: '/store/platform/rockstar-games',
    query: {},
  },
  {
    name: 'ubisoft connect',
    platform: 'Ubisoft Connect',
    url: '/store/platform/ubisoft-connect',
    query: {},
  },
  {
    name: 'ea play',
    platform: 'EA Play',
    url: '/store/platform/ea-play',
    query: {},
  },
  {
    name: 'battle.net',
    platform: 'Battle.net',
    url: '/store/platform/battlenet',
    query: {},
  },
];

export const giftCardsNav: Array<NavbarPopoverListInterface> = [
  {
    name: 'steam',
    url: '/store',
    query: { 'productType[]': 'giftcard', 'platform[]': ['Steam'] },
  },
  {
    name: 'xbox',
    url: '/store',
    query: {
      'productType[]': 'giftcard',
      'platform[]': ['Xbox Live'],
    },
  },
  {
    name: 'psn',
    url: '/store',
    query: {
      'productType[]': 'giftcard',
      'platform[]': ['PSN'],
    },
  },
  {
    name: 'nintendo',
    url: '/store',
    query: { 'productType[]': 'giftcard', 'platform[]': 'Nintendo' },
  },
  {
    name: 'google play',
    url: '/store',
    query: { 'productType[]': 'giftcard', 'platform[]': 'Android' },
  },
  {
    name: 'fortnite',
    url: '/store',
    query: { 'productType[]': 'giftcard', q: 'Fortnite' },
  },
  {
    name: 'roblox',
    url: '/store',
    query: { 'productType[]': 'giftcard', q: 'Roblox' },
  },
];

export const genresNav: Array<NavbarPopoverListInterface> = [
  {
    name: 'action',
    url: '/store/genre/action',
    query: {},
  },
  {
    name: 'adventure',
    url: '/store/genre/adventure',
    query: {},
  },
  {
    name: 'FPS/TPS',
    url: '/store',
    query: { 'genre[]': ['FPS', 'TPS'] },
  },
  {
    name: 'indie',
    url: '/store/genre/indie',
    query: {},
  },
  {
    name: 'racing',
    url: '/store/genre/racing',
    query: {},
  },
  {
    name: 'rpg',
    url: '/store/genre/rpg',
    query: {},
  },
  {
    name: 'simulation',
    url: '/store/genre/simulation',
    query: {},
  },
  {
    name: 'strategy',
    url: '/store/genre/strategy',
    query: {},
  },
];

const Categories: React.FC<{
  handleCategoriesClose: () => void;
}> = ({ handleCategoriesClose }) => {
  const { messages } = useLocale();
  const { platforms_msg, gift_cards_msg, genres_msg } = messages || {};

  return (
    <CategoriesContainer>
      <Column>
        <PlatformsContainer>
          <Title>{platforms_msg}</Title>
          <PlatformsList>
            {platforms.map((cur, i) => (
              <Link
                key={i}
                href={{
                  pathname: `${cur.url}`,
                  query: cur.query,
                }}
                passHref
                prefetch={false}
              >
                <a>
                  <ItemContainer
                    onClick={() => {
                      handleCategoriesClose();
                    }}
                  >
                    <ItemTitle>{cur.name}</ItemTitle>
                    <PlatformIcon>
                      {exportPlatformIcon(
                        cur.platform,
                        '24px',
                        '24px',
                        '0 0 0 0'
                      )}
                    </PlatformIcon>
                  </ItemContainer>
                </a>
              </Link>
            ))}
          </PlatformsList>
        </PlatformsContainer>
        <GiftCardsContainer>
          <Title>{gift_cards_msg}</Title>
          <GiftCardsList>
            {giftCardsNav.map((cur, i) => (
              <Link
                key={i}
                href={{
                  pathname: `${cur.url}`,
                  query: cur.query,
                }}
                passHref
                prefetch={false}
              >
                <a>
                  <ItemContainer
                    onClick={() => {
                      handleCategoriesClose();
                    }}
                  >
                    <ItemTitle>{cur.name}</ItemTitle>
                  </ItemContainer>
                </a>
              </Link>
            ))}
          </GiftCardsList>
        </GiftCardsContainer>
      </Column>
      <Column>
        <GenresContainer>
          <Title>{genres_msg}</Title>
          <GenresList>
            {genresNav.map((cur, i) => (
              <Link
                key={i}
                href={{
                  pathname: `${cur.url}`,
                  query: cur.query,
                }}
                passHref
                prefetch={false}
              >
                <a>
                  <ItemContainer
                    onClick={() => {
                      handleCategoriesClose();
                    }}
                  >
                    <ItemTitle>{cur.name}</ItemTitle>
                  </ItemContainer>
                </a>
              </Link>
            ))}
          </GenresList>
        </GenresContainer>
      </Column>
    </CategoriesContainer>
  );
};

export default Categories;
